import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "./assets/Fonts/em-print/styles.css";
import "./CkUI/styles/variables.css";
import "./theme/theme.css";

import { axios } from "shared";

import AuthenticationHelper from "./AuthenticationHelper";
import { Provider } from "react-redux";
import store from "./stores";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/es_ES";
import "moment/locale/es-mx";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL as string;

if (window.location.hostname.toLowerCase().includes("tallercontigo.com.mx")) {
  window.location.replace(`https://taller.carker.com.mx`);
}

if (
  window.location.protocol !== "https:" &&
  !window.location.href.includes("localhost")
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
}

axios.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {}
  return config;
});

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <HelmetProvider>
        <BrowserRouter>
          <AuthenticationHelper />
        </BrowserRouter>
      </HelmetProvider>
    </ConfigProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
