import { useState } from 'react';

interface ICookieOptions {
  days: number;
  path: string;
}

const isBrowser = typeof window !== 'undefined';

export function stringifyOptions(options: object) {
  return Object.keys(options).reduce((acc: string, key: string) => {
    if (key === 'days') {
      return acc;
    } else {
      if (options[key] === false) {
        return acc;
      } else if (options[key] === true) {
        return `${acc}; ${key}`;
      } else {
        return `${acc}; ${key}=${options[key]}`;
      }
    }
  }, '');
}


export const setCookie = (name: string, value: any, options: object) => {
  if (!isBrowser) return;

  const optionsWithDefaults : ICookieOptions = {
    days: 7,
    path: '/',
    ...options,
  };

  const expires = new Date(
    Date.now() + optionsWithDefaults.days * 864e5
  ).toUTCString();

  document.cookie =
    name +
    '=' +
    encodeURIComponent(value) +
    '; expires=' +
    expires +
    stringifyOptions(optionsWithDefaults);
};

export const getCookie = (name: string, initialValue : any = '') => {
  return (
    (isBrowser &&
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '')) ||
    initialValue
  );
};

export const deleteAllCookies = async () => {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

interface IUseCookie<T> {
  (key: string, initialValue: T) : [T, Function] 
}
function useCookie<T> (key:string, initialValue: T) : [T, Function] {
  const [item, setItem] = useState(() => {
    return getCookie(key, initialValue);
  });

  const updateItem = (value: any, options: object) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
}

export default useCookie;
