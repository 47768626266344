import { UserAPI } from "../Services/axios";
export const GetUserSocialNetwork = (email: string) => {

  return new Promise<any>((resolve, reject) => {

    UserAPI.isSocialNetworkUser(email)
      .then((identity: any) => {
        resolve({

          isSocialNetworkUser: identity.isSocialNetworkUser,
          source: identity.source,

        });
      }).catch(err => {
        console.log(err)
        let codeError = 1
        reject({ codeError: codeError });// message.error({ content: codeError, style: { zIndex: 4000 } });
      })
  });
};
