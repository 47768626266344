
var CryptoJS = require("crypto-js");

export const encryptHMAC = (text: string, isEndConsumer?: boolean) => {
  let encryptionKey;
  if (isEndConsumer) {
    encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY_EC;
  } else {
    encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY;
  }

  //STEP 1
  const hash = CryptoJS.SHA256(encryptionKey);
  const KeyEnc = hash.toString().slice(0, 32);
  const KeyMAC = hash.toString().slice(32);

  //STEP 2    
  const iv = KeyEnc.toString().slice(16);
  const ivUTF8 = CryptoJS.enc.Utf8.parse(iv);
  const keyEncUTF8 = CryptoJS.enc.Utf8.parse(KeyEnc);
  const plaintextUTF8 = CryptoJS.enc.Utf8.parse(text);

  //STEP 3 and 4
  const encrypted = CryptoJS.AES.encrypt(plaintextUTF8, keyEncUTF8,
    {
      iv: ivUTF8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })


  const encryptedText = iv.toString().concat(encrypted.toString());
  const HMAC = CryptoJS.HmacSHA256(encryptedText.toString(), KeyMAC);

  //STEP 6
  return encryptedText.concat(HMAC.toString());
}

export const hashSha256 = (value: string) => {
  const encrypted = CryptoJS.SHA256(value);
  return encrypted.toString(CryptoJS.enc.Hex);
} 
