import Axios from "axios";
import { UserAPI } from "../app/Services/axios";
import { encryptHMAC } from '../app/Utilities';
import { jwtDecode } from "jwt-decode";

/**
 * Class for rest auth testing
 */
interface IError {
  error_description: string;
  error: string;
}
export class AuthRest {

  constructor(){}

  private errorCodes = {
    AADB2C90225: {
      en: 'The username or password provided in the request are invalid.',
      es: 'El usuario o contraseña indicados no es válido'
    },
    AADB2C90118: {
      en: 'The user has forgotten their password.',
      es: 'Contraseña no válida.'
    },
    AADB2C99001: {
      en: 'You are already registered, please press the back button and sign in instead.',
      es: 'Su cuenta ya se encuentra registrada.'
    },
    AADB2C99002: {
      en: 'User does not exist. Please sign up before you can sign in.',
      es: 'Debe registrarse para iniciar sesión'
    },
    AADB2C90091: {
      en: 'The user has cancelled entering self-asserted information.',
      es: 'El usuario canceló la operación.'
    },
    AADB2C90273: {
      en: 'An invalid response was received',
      es: 'Ocurrió un problema procesando la operación. Por favor intenta nuevamente.'
    },
    AADB2C90075: {
      en: 'There was a problem processing your request. Please try again.',
      es: 'Ocurrió un problema procesando la operación. Por favor intenta nuevamente.'
    },
    AADB2C: {
      en: 'This email already belongs to the Carker platform.',
      es: 'Este correo ya pertenece a la plataforma Carker.'
    }
  }

  private accessTokenKeys = [
    'access_token',
    'id_token',
    'refresh_token'
  ];

  private signUpFlows = [
    'B2C_1_Google_Create_User', 'B2C_1_Facebook_Create_User'
  ];

  private response : {
    status: boolean,
    data: any,
    message: string
  } = {
    status: true,
    data: {},
    message: ''
  } 

  private resetResponse() {
    this.response = {
      status: true,
      data: {},
      message: ''
    }
  }

  private getRedirectUrl = (): string => window.location.origin;

  private hashToObject = (clearHash: boolean = true) => {
    // Get tokens from hash
    const hash = window.location.hash.substring(1, window.location.hash.length).split('&').reduce((tokens, currentParam) => {
      const [key, value] = currentParam.split("=");
      tokens[key] = value;
      return tokens;
    } , {});

    // Clear the hash
    if (clearHash) {
      window.location.hash = '';
    }

    return hash;
  }

  private getErrorMessage = (error: IError) => {
    let canContinue : boolean = true;
    if (typeof error.error_description === 'string') {
      Object.keys(this.errorCodes).forEach((code: string) => {
        if (error.error_description.indexOf(code) > -1 && canContinue) {
          this.response.message = this.errorCodes[code].es;
          canContinue = false;
        }
      });
      if (this.response.message === '') this.response.message = 'Se produjo un error al iniciar sesión.';
    }
  }

  public async lookForErrors () {
    // Resent response 
    this.resetResponse();

    // Get tokens from hash
    const hash = this.hashToObject(true);
    this.response.status = false;
    this.getErrorMessage(hash as IError);

    // Return response
    return this.response;
  }

  public setAccessTokens(tokens: object = {}) {
    this.accessTokenKeys.forEach((key:string) => {
      if (key in tokens) {
        localStorage.setItem(key, typeof tokens[key] === 'string' ? tokens[key] : JSON.stringify(tokens[key]));
      }
    })
  }

  public deleteAccessTokens() {
    this.accessTokenKeys.forEach(item => {
      localStorage.removeItem(item);
    })
  }

  public async lookForActiveSession () {
    // Reset object response
    this.resetResponse();

    // Let's try to retrieve tokens from localStorage
    let tokens : {
      access_token?: string,
      id_token?: string,
      refresh_token?: string
    } | undefined = {};
    try {
      tokens = this.accessTokenKeys.reduce((tokens: object, key: string) => {
        tokens[key] = localStorage.getItem(key);
        return tokens;
      }, {});
    } catch (e) {
      tokens = undefined;
    }

    // if we got an error, there is nothing to do here, return the response with false
    if (tokens === undefined) {
      this.response.status = false;
      return this.response
    }

    // if access_token ain't listed, see you lator alligator, but remember return the response with error
    if (tokens.access_token === null  || tokens.access_token === undefined) {
      this.deleteAccessTokens();
      this.response.status = false;
      return this.response;
    }

    // are you really reading this comments? great =)
    // We also need to check if this token have not expired yet 
    const access_token = jwtDecode(tokens.access_token);

    // Check app audience and expiration date
    if (access_token.aud === undefined || access_token.aud !== this.audience || access_token.exp >= Date.now()*1000) {
      this.deleteAccessTokens();
      this.response.status = false;
      return this.response;
    }

    // Request tokens handle
    await this.handleLoginTokens(tokens, false);
    
    // return response
    return this.response;
  }

  public async login({
    username,
    password
  }: {
    username: string,
    password: string
  }) {
    // Reset object response
    this.resetResponse();

    // Request access token to tenant
    const requestToken = await Axios
      .post(`${process.env.REACT_APP_AUTHORITY_ROPC_SIGNIN}?grant_type=password&client_id=${encodeURI(process.env.REACT_APP_ROPC_CLIENT_ID!)}&scope=openid+${encodeURI(process.env.REACT_APP_ROPC_CLIENT_ID!)}+offline_access&response_type=token+id_token&password=${encodeURIComponent(password)}&username=${encodeURI(username)}`,
        {
          grant_type: "password",
          username,
          password,
          scope: `openid ${process.env.REACT_APP_ROPC_CLIENT_ID} offline_access`,
          client_id: process.env.REACT_APP_ROPC_CLIENT_ID,
          response_type: 'token id_token'
        })
      .then(response => {
        const { data } = response;
        return {
          access_token: data.access_token,
          id_token: data.id_token,
          refresh_token: data.refresh_token
        };
      })
      .catch(error => {
        this.response.status = false;
        // Search for error message
        if ('response' in error) {
          const { data } = error.response;
          this.getErrorMessage(data);
        } else {
          this.response.message = 'Se produjo un error al iniciar sesión.'
        }
        return undefined;
      })

    // if requestToken is empty, return error
    if (requestToken === undefined) return this.response;

    // Request tokens handle
    await this.handleLoginTokens(requestToken);
    
    // return response
    return this.response;
  }

  public async urlLogin() {
    // Resent response 
    this.resetResponse();

    // Get tokens from hash
    const tokens = this.hashToObject(true);

    // Request tokens handle
    await this.handleLoginTokens(tokens);
  
    // Return response
    return this.response;
  }

  public async signUp({
    firstName,
    lastName,
    email,
    password
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }, isEndConsumer: boolean) {
    // Reset object response
    this.resetResponse();

    const requestToken = await UserAPI
      .signUp({
        firstName,
        lastName,
        email,
        password: encryptHMAC(password, isEndConsumer)
      },
      isEndConsumer)
      .then(response => {
        const { data } = response;
        // Check if we have any error on sign up request
        if (typeof data.error === 'string') {
          // TODO: Refactor error response
          this.response.message = 'Se produjo un error al registrar el usuario';
          this.response.status =  false;
          return undefined;
        } else {
          // else, return tokens
          return {
            access_token: data.access_token,
            id_token: data.id_token,
            refresh_token: data.refresh_token
          };
        }
      })
      .catch(error => {
        this.response.status = false;
        // Search for error message
        if ('response' in error) {
          if (parseInt(error.response.status) === 409) {
            this.response.message = 'Su cuenta ya se encuentra registrada.';
            return undefined;
          }
          const { data } = error.response;
          this.getErrorMessage(data);
        } else {
          this.response.message = 'Se produjo un error al iniciar sesión.';
        }
        return undefined;
      });

    // if requestToken is empty, return error
    if (requestToken === undefined) return this.response;

    // Request tokens handle
    await this.handleLoginTokens(requestToken, true, true);

    // return response
    return this.response;
  }

  private async handleLoginTokens(tokens: object, storeTokens: boolean = true, isNew:boolean | undefined = false) {
    // set access tokens on localStorage
    storeTokens && this.setAccessTokens(tokens); 

    // request user data
    const user = await UserAPI
      .me()
      .then(response => response.data)
      .catch(() => undefined);

    // If user data is not available, return arror and delete tokens from localStorage
    if (user === undefined) {
      this.response = {
        data: {},
        status: false,
        message: 'Se produjo un error al consultar los datos del usuario.'
      }
      this.deleteAccessTokens();
      return this.response;
    }

    const userToken = jwtDecode(tokens.access_token);
    
    if (userToken && userToken.tfp && this.signUpFlows.includes(userToken.tfp)) {
      user.isNew = true;
    } else {
      user.isNew = false;
    }
    if (isNew !== undefined) user.isNew = isNew;
    
    if (userToken && userToken.extension_MustResetPassword) {
      user.extension_MustResetPassword = userToken.extension_MustResetPassword;
    }

    // Set user on response data
    this.response.data.user = user;

    // Check if user is not enabled
    if (user.isEnabled !== true) {
      this.response.status = false;
      this.response.message = "Tu usuario se encuentra inhabilitado, por favor contacta a servicio al cliente para mas detalles.";
      this.deleteAccessTokens();
    }
    return this.response;
  }

  // TODO: fix this
  public oAuthUrls = {
    google: {
      login: `${process.env.REACT_APP_AUTHORITY_GOOGLE_LOGIN}&client_id=${
        process.env.REACT_APP_CLIENT_ID
      }&nonce=defaultNonce&redirect_uri=${encodeURI(
        this.getRedirectUrl()
      )}&scope=${encodeURI(
        process.env.REACT_APP_SCOPES!
      )}&response_type=token&prompt=login`,
      signup: `${process.env.REACT_APP_AUTHORITY_GOOGLE_SIGNUP}&client_id=${
        process.env.REACT_APP_CLIENT_ID
      }&nonce=defaultNonce&redirect_uri=${encodeURI(
        this.getRedirectUrl()
      )}&scope=${encodeURI(
        process.env.REACT_APP_SCOPES!
      )}&response_type=token&prompt=login`,
    },
    facebook: {
      login: `${process.env.REACT_APP_AUTHORITY_FACEBOOK_LOGIN}&client_id=${
        process.env.REACT_APP_CLIENT_ID
      }&nonce=defaultNonce&redirect_uri=${encodeURI(
        this.getRedirectUrl()
      )}&scope=${encodeURI(
        process.env.REACT_APP_SCOPES!
      )}&response_type=token&prompt=login`,
      signup: `${process.env.REACT_APP_AUTHORITY_FACEBOOK_SIGNUP}&client_id=${
        process.env.REACT_APP_CLIENT_ID
      }&nonce=defaultNonce&redirect_uri=${encodeURI(
        this.getRedirectUrl()
      )}&scope=${encodeURI(
        process.env.REACT_APP_SCOPES!
      )}&response_type=token&prompt=login`,
    }
  };

  public audience = process.env.REACT_APP_CLIENT_ID;

  public async logout() {
    // Reset object response
    this.resetResponse();
    this.deleteAccessTokens();
    // return response
    return this.response;
  }
}
