import Axios, { AxiosResponse } from "axios";
import {
  Vehicle,
  EndConsumerFeedback,
  RatingDetail,
} from "shared/src/data-models/EndConsumer";
import {
  IPFilterWorkshop,
  WorkshopPublicDetail,
  WorkshopPublicBasicInfo,
} from "shared/src/data-models/WorkshopPublic";
import {
  VehicleBrand,
  VehicleLicensePlateState,
  VehicleMainPurpose,
  VehicleModel,
} from "shared/src/data-models/Catalogs";
import {
  Balance,
  RedeemPetition,
  RedeemResponse,
  Reward,
} from "../models/Promocodes";
import { Product } from "../models/oli";
import { WorkshopService, WorkshopType } from "shared/src/data-models/Workshop";
import { User } from "shared/src/data-models/User";
import { Appointment } from "shared";
import moment from "moment";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

axios.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {}
  return config;
});

export const requests = {
  get: (url: string, params?: any) => axios.get(url, { params }),
  post: (url: string, body: {}) => axios.post(url, body),
  put: (url: string, body: {}) => axios.put(url, body),
  del: (url: string) => axios.delete(url),
  patch: (url: string, body: {}) => axios.patch(url, body),
};

export const WorkshopAPI = {
  getById: (id: number): Promise<AxiosResponse<WorkshopPublicDetail>> =>
    requests.get(`/publicInfo/workshops/${id}`),
  getByIdAndCoord: (id: number, coord: string, userTimeZone: string = "360"): Promise<AxiosResponse<WorkshopPublicDetail>> =>
    requests.get(`/publicInfo/workshops/${id}?coordinates=${coord}&userTimeZone=${userTimeZone}`),
  get: (
    filters?: IPFilterWorkshop
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops`, filters),
  search: (
    filters?: IPFilterWorkshop
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops/search`, filters),
  searchDuplicates: (
    workshopEmail?: string,
    workshopName?: string,
    sucursalName?: string
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(
      `/workshops/searchduplicates?${
        workshopEmail != undefined ? `workshopEmail=${workshopEmail}` : ""
      }${workshopName != undefined ? `workshopName=${workshopName}` : ""}
      ${sucursalName != undefined ? `&sucursalName=${sucursalName}` : ""}`
    ),
  validateWorkshopIdentifier: (
    workshopIdentificator?: string,
    unencodedOwnerEmail?: string,
    workshopId: string = "0"
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(
      `/workshops/${workshopId}/ValidateIdentifier?WorkshopIdentificator=${workshopIdentificator}&OwnerEmail=${encodeURIComponent(
        unencodedOwnerEmail
      )}`
    ),
  photosCount: (url: string): Promise<AxiosResponse<any>> =>
    requests.get(`/workshops/photos/count?url=${url}`),
  getWorkshopAverageResponseTime: (
    workshopId: number
  ): Promise<AxiosResponse<string>> =>
    requests.get(`/publicInfo/workshops/responseTime/${workshopId}`),
  areCoordinatesAllowed: (
    workshopId: number,
    coordinates: string
  ): Promise<AxiosResponse<boolean>> =>
    requests.get(
      `/workshops/coordinatesAllowed/${workshopId}?coordinates=${coordinates}`
    ),
  getDashboard: (workshopId: string | number): Promise<AxiosResponse<any>> =>
    requests.get(`/workshop/${workshopId}/dashboard`),
  getDashboardResults: (
    workshopId: string | number,
    dateFrom: string,
    dateTo: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/workshop/${workshopId}/dashboardResults?dateFrom=${dateFrom}&dateTo=${dateTo}`
    ),
  setRecentlyApprovedToFalse: (
    workshopId
  ): Promise<AxiosResponse<any>> =>
    requests.put(
      `workshops/${workshopId}/RecentlyApprove`,
      {}
    )
};

export const VehicleAPI = {
  get: (): Promise<AxiosResponse<Vehicle[]>> => requests.get("/vehicles"),
  getById: (id: string): Promise<AxiosResponse<Vehicle>> =>
    requests.get(`/vehicles/byId/${id}`),
  add: (vehicle: Vehicle): Promise<AxiosResponse<Vehicle>> =>
    requests.post("/vehicles", vehicle),
  update: (vehicle: Vehicle): Promise<AxiosResponse<Vehicle>> =>
    requests.put(`/vehicles/byId/${vehicle.licensePlate}`, vehicle),
  delete: (id: string) => requests.del(`/vehicles/byId/${id}`),
  setIsMain: (
    id: string,
    isMainVehicle: boolean
  ): Promise<AxiosResponse<Vehicle>> =>
    requests.patch(`/vehicles/byId/${id}`, [
      {
        path: "/IsMainVehicle",
        op: "replace",
        value: isMainVehicle,
      },
    ]),
};

export const CatalogAPI = {
  getRecommendedProducts: (
    vehicleId: string,
    serviceCodeList: string,
    userId: string | undefined,
    servicePackageList?: string | undefined
  ): Promise<AxiosResponse<Product[]>> =>
    requests.get(
      `catalogs/productRecommendations/byId?vehicleId=${vehicleId}&serviceCodeList=${serviceCodeList}&userId=${userId}&servicePackageList=${servicePackageList}`
    ),
  getVehicleMainPurposes: async (
    language: string
  ): Promise<AxiosResponse<VehicleMainPurpose[]>> =>
    requests.get(`/catalogs/vehicleMainPurposes?language=${language}`),
  getVehicleLicensePlateStates: async (
    language: string
  ): Promise<AxiosResponse<VehicleLicensePlateState[]>> =>
    requests.get(`/catalogs/vehicleLicensePlateStates?language=${language}`),
  getWorkshopTypes: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<WorkshopType[]>> =>
    requests.get(`/catalogs/workshopTypes?language=${language}`),
  getServices: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/catalogs/services?language=${language}`),
  getDays: (language: string = "ES-MX"): Promise<AxiosResponse<[]>> =>
    requests.get(`/catalogs/days?language=${language}`),
  getVehicleBrands: async (
    language: string
  ): Promise<AxiosResponse<VehicleBrand[]>> =>
    requests.get(`/catalogs/vehicleBrands?language=${language}`),
  getVehicleModels: async (
    language: string,
    brandCode?: string,
    year?: string
  ): Promise<AxiosResponse<VehicleModel[]>> =>
    requests.get("/catalogs/vehicleModels", {
      ...{ language: language },
      ...(brandCode ? { vehicleBrandCode: brandCode } : {}),
      ...(year ? { modelYear: year } : {}),
    }),

  getRatingDetails: (
    language: string
  ): Promise<AxiosResponse<RatingDetail[]>> =>
    requests.get(`/catalogs/endConsumerFeedbackCategory?language=${language}`),
  getVehicleAccesories: (language: string): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/vehicleAccesories?language=${language}`),
  getColonies: (postalCode: string): Promise<AxiosResponse<any>> =>
    requests.get(`/catalogs/colonies?postalCode=${postalCode}`),
  getLocationInformation: (
    latitude: string,
    longitude: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/catalogs/locationInfo?latitude=${latitude}&longitude=${longitude}`
    ),
};

export const RatingAPI = {
  addByAppointment: (
    rating: EndConsumerFeedback[]
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/endConsumerFeedback`, {
      EndConsumerFeedbacks: rating,
    }),
  getCategoryReports: (): Promise<AxiosResponse<any>> =>
    requests.get(`/catalogs/EndConsumerFeedbackReportReason`),
  putReport: (id: string, code: string): Promise<AxiosResponse<any>> =>
    requests.put(
      `/endConsumerFeedback/Messages/${id}?ReportReasonCode=${code}`,
      {}
    ),
  getWorkshopTags: (
    workshopId: string | undefined
  ): Promise<AxiosResponse<any>> =>
    requests.get(`endConsumerFeedback/${workshopId}/categories/count`),
};

export const UserAPI = {
  me: async (): Promise<AxiosResponse<User>> => requests.get("users/me"),
  update: async (user: User): Promise<AxiosResponse<User>> =>
    requests.put(`users/${user.id}`, user),
  updatePassword: async (password: string, isNewUser?: boolean) =>
    requests.put(
      `/b2c/users/me?isEndConsumer=false${
        isNewUser !== undefined ? `&isNewUser=${isNewUser}` : ""
      }`,
      { password: password }
    ),
  // requests.put(`/b2c/users/me?isEndConsumer=false`, { password: password }),
  delete: async (id: string) => requests.del(`users/${id}`),
  checkUser: async (email: string) =>
    requests.get("users/isB2CUser", { email: email }),
  isSocialNetworkUser: async (email: string) =>
    requests.get("users/isSocialNetworkUser", { email: email }),
  getUserNotifications: async (userId: string | undefined) =>
    requests.get("notifications/bellNotifications", { userId: userId }),
  setUserNotificationAsRead: async (notificationId: number | undefined) =>
    requests.put(
      `notifications/bellNotifications?NotificationId=${notificationId}`,
      { notificationId }
    ),
  clearNotifications: async (
    payload: Array<any>,
    toNew?: boolean
  ): Promise<AxiosResponse<User>> =>
    requests.put(`notifications/bellNotifications/All`, payload),
  setAsNotNewNotifications: async (
    payload: Array<any>
  ): Promise<AxiosResponse<User>> =>
    requests.put(
      `notifications/bellNotifications/All?isNewField=true`,
      payload
    ),
  signUp: async (
    data: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    },
    isEndConsumer: boolean
  ) => requests.post(`b2c/users?isEndConsumer=${isEndConsumer}`, data),
  getUserStatus: (email: string) => {
    return requests.get(`ConfirmUser?userEmail=${email}`);
  },
};

type SasTokenStorage = {
  sasToken: string;
  expire: number;
};

export const ConfigurationsAPI = {
  getSasToken: async (): Promise<{ data: string }> => {
    if (sessionStorage.getItem("sasToken")) {
      const sasTokenObj: SasTokenStorage = JSON.parse(
        sessionStorage.getItem("sasToken")!
      );
      if (sasTokenObj.expire > Date.now()) {
        return new Promise((resolve) =>
          resolve({ data: sasTokenObj.sasToken })
        );
      }
    }
    const request = await requests.get("configurations/sasToken");

    if (request.status >= 200 && request.status < 300) {
      sessionStorage.setItem(
        "sasToken",
        JSON.stringify({
          sasToken: request.data,
          expire: new URLSearchParams(request.data).has("se")
            ? new Date(new URLSearchParams(request.data).get("se")!).valueOf()
            : Date.now() + 300000,
        })
      );
      return new Promise((resolve) =>
        resolve({
          data: request.data,
        })
      );
    }
    return new Promise((resolve) => resolve(request));
  },
  getSasTokenNonRegistered: async (): Promise<{ data: string }> => {
    if (sessionStorage.getItem("sasToken")) {
      const sasTokenObj: SasTokenStorage = JSON.parse(
        sessionStorage.getItem("sasToken")!
      );
      if (sasTokenObj.expire > Date.now()) {
        return new Promise((resolve) =>
          resolve({ data: sasTokenObj.sasToken })
        );
      }
    }
    const request = await requests.get(`/configurations/sasTokenNonRegistered`);

    if (request.status >= 200 && request.status < 300) {
      sessionStorage.setItem(
        "sasToken",
        JSON.stringify({
          sasToken: request.data,
          expire: new URLSearchParams(request.data).has("se")
            ? new Date(new URLSearchParams(request.data).get("se")!).valueOf()
            : Date.now() + 300000,
        })
      );
      return new Promise((resolve) =>
        resolve({
          data: request.data,
        })
      );
    }
    return new Promise((resolve) => resolve(request));
  },
  showReCaptcha: async (
    reCaptchaType: "Workshop" | "EndConsumer" | "SuperUser"
  ): Promise<AxiosResponse<Appointment>> =>
    requests.get(`configurations/ShowReCaptcha?reCaptchaType=${reCaptchaType}`),
};

export const AppointmentAPI = {
  add: async (
    appointment: Appointment,
    workshopId: string
  ): Promise<AxiosResponse<Appointment>> =>
    requests.post(`/workshops/${workshopId}/appointments`, appointment),
  update: async (
    appointment: Appointment,
    workshopId: string
  ): Promise<AxiosResponse<Appointment>> =>
    requests.put(
      `/workshops/${workshopId}/appointments/${appointment.id}`,
      appointment
    ),
  addReception: async (
    payload: any,
    appId: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/appointmentTrackingReception/${appId}`, payload),
  getTrackingReception: async (id: string): Promise<AxiosResponse<any>> =>
    requests.get(`/appointmentTrackingReception/${id}`),
  updateDiagnosticServices: async (
    payload: any[],
    appointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/appointmentTrackingService/${appointmentId}`, payload),
  updateDiagnosticPackages: async (
    payload: any[],
    appointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/appointmentTrackingPackageSet/${appointmentId}`, payload),
  updateDiagnosticQuotation: async (
    payload: any,
    appointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(
      `/appointmentTrackingDiagnosticQuotation/${appointmentId}`,
      payload
    ),
  saveQuotationDraft: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`/Quotation/SaveQuotationDraft`, payload),
  sendQuotationToEndConsumer: async (
    payload: any
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/Quotation/SendQuotationToEndConsumer`, payload),
  approveQuotation: async (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`/Quotation/ApproveQuotationForWorkshop`, payload),
  confirm: async (
    id: string,
    code: string,
    comment: string
  ): Promise<AxiosResponse<any>> =>
    axios.put(
      `/workshops/appointmentsNonRegistered/${id}/?validationCode=${code}`,
      `"${comment}"`,
      { headers: { "Content-Type": "application/json" } }
    ),
  getAppById: async (appId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/workshops/appointments/${appId}`),
};

export const PromoCodesAPI = {
  getRewards: async (): Promise<AxiosResponse<Reward[]>> =>
    requests.get("/promoCodes/rewards"),
  getUserBalance: async (userId: number): Promise<AxiosResponse<Balance>> =>
    requests.get(`/promoCodes/balance/${userId}`),
  redeemReward: async (
    redeem: RedeemPetition
  ): Promise<AxiosResponse<RedeemResponse>> =>
    requests.post("/promoCodes/redeem", redeem),
  redeemNotification: async (redeem: {
    email: string;
    prizeRedeemedCode: string;
  }): Promise<AxiosResponse<boolean>> =>
    requests.post("/promoCodes/redeemnotification", redeem),
  getInvitationCode: async (invitation: {
    userReferrerId: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/code", invitation),
  inviteUser: async (invitation: {
    senderUserId: number;
    email: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/invitations", invitation),

  checkUserInvite: async (user: {
    userInvitedID: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.put("/promoCodes/usersignup", user),
};

export const CommunicationAPI = {
  getRequests: async ({
    workshopId,
    dateFrom,
    dateTo,
    requestStatus,
    requestType,
    orderType = "DESC",
    page = 1,
    pageSize = 20,
    endConsumerRequestId,
  }: {
    workshopId: string;
    dateFrom: string;
    dateTo: string;
    requestStatus?: string;
    requestType?: string;
    orderType: "ASC" | "DESC";
    page: number;
    pageSize: number;
    endConsumerRequestId?: string;
  }): Promise<
    AxiosResponse<{
      pages: number;
      requests: any[];
      totalRequests: number;
    }>
  > => {
    const params = {
      workshopId,
      dateFrom,
      dateTo,
      ...(requestStatus ? { requestStatus: requestStatus } : {}),
      ...(requestType ? { requestType: requestType } : {}),
      orderType,
      page,
      pageSize,
      ...(endConsumerRequestId
        ? { endConsumerRequestId: endConsumerRequestId }
        : {}),
    };
    return requests.get(
      `/WorkshopRequestsFilters?${new URLSearchParams(params).toString()}`
    );
  }, // WorkshopRequestsFilters
  message: async (payload: any): Promise<AxiosResponse<string>> =>
    requests.post(`/Message`, payload),
  closeRequest: async (payload: any): Promise<AxiosResponse<string>> =>
    requests.put(`/closeRequest`, payload),
  updateService: async (payload: any): Promise<AxiosResponse<String>> =>
    requests.post(`/EndConsumerRequest/services`, payload),
  getMessages: async (appId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/${appId}/EndConsumerRequests`),
  getImages: async (
    workshopId: string,
    userId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/chatProfilePhotos/${workshopId}/user/${userId}`),
  doReport: async (payload: any): Promise<AxiosResponse<string>> =>
    requests.post(`/userClaims`, payload),
};
// export const EnrollmentQuizAPI  = {
//    getQuizSection: async (
//       language?: string
//    ): Promise<AxiosResponse<QuizSection>> =>
//    requests.get(`/catalogs/quizSections`),

//    getSectionQuestions: async (
//      sectionCode?:string,
//      language?:string
//      ):Promise<AxiosResponse<SectionQuestions>> =>
//    requests.get(`/catalogs/quizQuestions?quizSectionCode=${sectionCode}`),

//    getQuestionAnswer: async (
//     questionCode?:string,
//     language?:string
//    ):Promise<AxiosResponse<QuestionAnswer>> =>
//    requests.get(`/catalogs/quizAnswers?quizQuestionCode=${questionCode}`),

//    getChecklist: async (
//      language?:string
//    ): Promise<AxiosResponse<QuizChecklist>> =>
//    requests.get(`/catalogs/quizChecklist`),

// postEnrollmentQuiz: async (
//   vehicle: Vehicle
//   ): Promise<AxiosResponse<EnrollmentQuiz>> =>
// requests.post(`/enrollmentOwnerQuiz`),

// };

export const WarrantyAPI = {
  getWarranty: async (
    workshopId: string | number
  ): Promise<AxiosResponse<any>> =>
    requests.get(`workshop/${workshopId}/warranty`),
  updateWarranty: async (
    workshopId: string,
    payload: any[]
  ): Promise<AxiosResponse<any>> =>
    requests.post(`workshop/${workshopId}/warranty`, payload),
};

export const ServicesAPI = {
  updateServices: async (
    workshopId: string,
    payload: any[]
  ): Promise<AxiosResponse<any>> =>
    requests.post(`workshop/${workshopId}/serviceTypes`, payload),
  getWorkshopCategories: async (
    workshopId: string | number
  ): Promise<AxiosResponse<any>> =>
    requests.get(`workshop/${workshopId}/serviceTypes`),
  getWorkshopServices: async (
    workshopId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`workshop/${workshopId}/serviceByType`),
  getAllServices: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<any>> =>
    requests.get(`catalogs/serviceTypesCarousel?language=${language}`),
  getAppointmentServices: async (
    workshopId: string,
    appointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `workshop/${workshopId}/serviceByType?apptId=${appointmentId}`
    ),
};

export const PackagesAPI = {
  getPackagesDetail: async (): Promise<AxiosResponse<any>> =>
    requests.get(`/servicepackages/detail`),
  getWorkshopPackages: async (
    workshopId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/servicepackages/${workshopId}`),
  postWorkshopPackages: async (
    workshopId: string,
    payload: any[]
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/servicepackages/${workshopId}`, payload),
};

export const ClientsAPI = {
  addTagToWS: async (workshopId, tag): Promise<any> => axios.post(`/workshop/${workshopId}/CustomerTags`, `"${tag}"`, { headers: { "Content-Type": "application/json" } }),
  getTags: async (workshopId): Promise<any> => requests.get(`/workshop/${workshopId}/CustomerTags`),
  updateTag: async (workshopId, tagId, payload): Promise<any> => requests.put(`/workshop/${workshopId}/CustomerTags/${tagId}`, payload),
  deleteTag: async (workshopId, tagId): Promise<any> => requests.del(`/workshop/${workshopId}/CustomerTags/${tagId}`),
  addClient: async (workshopId, client): Promise<any> => axios.post(`/workshop/${workshopId}/Customer`, client ),
  addClients: async (workshopId, clients): Promise<any> => axios.post(`/workshop/${workshopId}/Customers`, clients),
  updateClient: async (workshopId, customerId, customer): Promise<any> => axios.put(`/workshop/${workshopId}/Customer/${customerId}`, customer ),
  deleteClient: async (workshopId, clientId): Promise<any> => axios.delete(`/workshop/${workshopId}/Customer/${clientId}`),
  getClients: async (
    workshopId: string,
    order: string,
    vehicleTypeFilter?: string,
    vehicleBrandFilter?: string,
    vehicleYearFilter?: string,
    vehicleYearStartFilter?: string,
    licensePlateFilter?: string,
    tagsFilter?: string,
    serviceFilter?: string,
    lastServicePeriodFilter?: string,
  ) : Promise<any> => requests.get(
    `/workshop/${workshopId}/Customer?SortLastName=${order}${
      vehicleTypeFilter? `&EndConsumerVehicleTypeFilter=${vehicleTypeFilter}`: ""}${
      vehicleBrandFilter? `&endConsumerVehicleBrandFilter=${vehicleBrandFilter}` : ""}${
      vehicleYearFilter? `&EndConsumerVehicleYearFilterStart=${vehicleYearStartFilter}&EndConsumerVehicleYearFilterEnd=${vehicleYearFilter}`: ""}${
      licensePlateFilter? `&EndConsumerVehicleLicensePlateFilter=${licensePlateFilter}`: ""}${
      tagsFilter? `&TagsFilters=${tagsFilter}`: ""}${
      serviceFilter? `&serviceNameFilter=${serviceFilter}`: ""}${
      lastServicePeriodFilter? `&LastAppointmentDateFilter=${lastServicePeriodFilter}` : ""}`
  ),
  getClient: async (workshopId, customerId) : Promise<any> => requests.get(`/workshop/${workshopId}/Customer/${customerId}`),
  addTagToCustomer: async (workshopId, tagId, customerIdArray): Promise<any> => axios.post(`/workshop/${workshopId}/CustomerTags/${tagId}`, customerIdArray, { headers: { "Content-Type": "application/json" } }),
}

export const FeedbackAPI = {
  getWorkshopRatingSummary: async (
    workshopId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/endConsumerFeedback/WorkshopRatingSummary/${workshopId}`),
  getWorkshopRating: async (
    workshopId: string,
    page: number,
    pageSize: number,
    dateFrom: string,
    dateTo: string,
    rankingFrom: number,
    rankingTo: number,
    sortCriteria: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/endConsumerFeedback/WorkshopRating/${workshopId}?page=${page}&pageSize=${pageSize}${
        dateFrom ? `&dateFrom=${dateFrom}` : ""
      }${
        dateTo ? `&dateTo=${dateTo}` : ""
      }&sortCriteria=${sortCriteria}&rankingFrom=${rankingFrom}&rankingTo=${rankingTo}`
    ),
  getWorkshopRatingVisible: async (
    workshopId: string,
    page: number,
    pageSize: number,
    sortDesc?: boolean,
    sortCriteria?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `endConsumerFeedback/WorkshopRatingVisible/${workshopId}?page=${
        page ? page : 1
      }&pageSize=${pageSize ? pageSize : 0}${
        sortDesc !== undefined ? `&sortDesc=${sortDesc}` : ""
      }${
        sortCriteria !== undefined ? `&sortCriteria=${sortCriteria}` : ""
      }&hideReported=${false}`
    ),
  getRatingOverall: async (
    workshopId: string,
    payload: any
  ): Promise<AxiosResponse<any>> => {
    return requests.patch(
      `/endConsumerFeedback/${workshopId}/RatingOverall`,
      payload
    );
  },
  getWorkshopsWithRating: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/endConsumerFeedback/WorkshopsWithRating/`),
  hideMessage: async (messageId: string): Promise<AxiosResponse<any>> =>
    requests.put(`/endConsumerFeedback/HideMessages/${messageId}`, ""),
  publishMessage: async (messageId: string): Promise<AxiosResponse<any>> =>
    requests.put(`/endConsumerFeedback/ShowMessages/${messageId}`, ""),
  addMessage: async (
    messageId: string,
    comment: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(
      `/endConsumerFeedback/Messages/${messageId}?Message=${comment}`,
      ""
    ),
  reportUser: async (
    userId: string,
    comment: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/userClaims/reportUser`, {
      UserReportedId: userId,
      Comment: comment,
    }),
};
